import { Button, Checkbox, Form, Modal, notification, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import FormFields from '../../../../component/common/FormFields';
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import TaskSubmissionModal from './task-submission-modal';
import WithdrawModal from './withdraw-modal';


const TaskApply = ({ admindetails, api, setRefresh, show = false, status, setStatus, taskData, applyUserTaskHandler, isModalVisible }) => {
    const [form] = Form.useForm();
    const [checked, setChecked] = useState(false);
    const [isButton, setIsButton] = useState(false);

    const isButtonHandler = () => {
        setIsButton(false)
    }

    const [isSucess, setIsSucess] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null); 

    // const { userTaskId, userId } = useParams();
    // const api = useHttp();
    const formattedPathapply = taskData?.availableFileType ? '.' + taskData?.availableFileType?.split(',')?.join(',.') : "all";
    useEffect(() => {
        if (!isSucess) return
        const timer = setTimeout(() => {
            setRefresh(pr => !pr)
            isModalVisible();
            form?.resetFields();
            setIsSucess(pr => !pr)
        }, 3000);
        setTimeoutId(timer);
        return () => clearTimeout(timer);
    }, [isSucess]);

    return (
        <>
            <Modal width={440}
                open={show}
                onCancel={() => {
                    form?.resetFields();
                    setChecked(false)
                    isModalVisible();
                    isButtonHandler();
                    if (timeoutId) {
                        clearTimeout(timeoutId); // Clear the timeout when modal is closed
                        setTimeoutId(null); 
                        setIsSucess(false);
                        setRefresh(pr => !pr)
                    }

                }}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}  >
                {/* APPLY FORM */}
                {status === "Apply" ? <div>
                    <p className='py-2 font-semibold'>
                        Terms & Condition
                    </p>
                    <div className='py-2' dangerouslySetInnerHTML={{ __html: taskData?.termAndCondition }} />
                    <div className='py-4'>
                        <Checkbox checked={checked} onChange={(e) => {
                            e.preventDefault();
                            setChecked(e?.target?.checked);
                            setIsButton(pr => !pr);
                        }}>I have read terms & conditions. and i'm accepted.</Checkbox>

                    </div>
                    <Button loading={api?.isLoading} className={`mt-2 w-full`} type='primary' style={{
                        background: !isButton ? "#DBECFF80" : "#007BFF",
                        color: !isButton ? "#007BFF" : "#ffffff",
                    }} onClick={() => {
                        if (!checked) return notification.error({ message: "Please accept terms & conditions" })

                        applyUserTaskHandler(isModalVisible, isButtonHandler);
                    }}>
                        Apply
                    </Button>
                </div> : status === "InProgress" || status === "Reject" || status === "Rework" ?
                    <TaskSubmissionModal
                        form={form}
                        taskData={taskData}
                        admindetails={admindetails}
                        setRefresh={setRefresh}
                        isModalVisible={isModalVisible}
                        isButton={isButton}
                        setIsButton={setIsButton}
                        isSucess={isSucess}
                        setIsSucess={setIsSucess}
                        formattedPathapply={formattedPathapply}
                        setStatus={setStatus} /> :
                    status === "Pending" ?
                        <WithdrawModal
                            taskData={taskData}
                            isModalVisible={isModalVisible}
                            setRefresh={setRefresh} /> : <></>}
            </Modal>
        </>
    )
}

export default TaskApply