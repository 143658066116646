import { Button, Card, Col, Form, Row } from 'antd'
import React from 'react'
import FormFields from '../../../component/common/FormFields'
import CONSTANTS, { appRoot } from '../../../util/constant/CONSTANTS';
import ImageProfileUploader from '../../../component/common/profile-image-uploader';
import useHttp from '../../../hooks/use-http';
import { payloadGenerator } from '../../../component/common/CRUD-Component';
import { useNavigate } from 'react-router-dom';
import { apiGenerator } from '../../../util/functions';
import RegistrationCard from '../../../component/modalCard/registrationCard';

const RegistrationPage = ({ admindetails = {} }) => {
    const [form] = Form.useForm();
    // const api = useHttp();
    // const navigate = useNavigate();

    // const handleFormSubmit = () => {
    //     console.log(form?.getFieldsValue())
    //     form
    //         .validateFields()
    //         .then((values) => {
    //             console.log(values);
    //             const payload = payloadGenerator({
    //                 ...values,
    //                 isAffiliateUser: true
    //             }, CONSTANTS.FORM_FIELD.REGISTRATION_PAGE_MODAL, false);
    //             console.log(payload);

    //             // if (admindetails?.id) {
    //             //     api.sendRequest(apiGenerator(CONSTANTS.API.editProfile, {
    //             //         dataId: admindetails?.id
    //             //     }), () => {

    //             //     })
    //             // }
    //             // console.log(first)
    //             // api.sendRequest(
    //             //     CONSTANTS.API.signUp, (res) => {
    //             //         // console.log(res);
    //             //         //navigation link================================
    //             //         navigate(appRoot);
    //             //     }, payload, "Your detail sucessfully saved",
    //             // )

    //         })
    //         .catch((info) => {
    //             console.log("Validate Failed:", info);
    //         });
    // };
    return (
        <>
            <div
                className=" flex justify-center items-center px-6 md:px-40  py-10 bg-[#F0F2F5]"
            >
                <Row >
                    <Col span={24}>
                        <Card className='bg-white'>
                            <RegistrationCard admindetails={admindetails} form={form} />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default RegistrationPage