import { Button, Col, Form, notification, Row } from 'antd';
import { InputOTP } from 'antd-input-otp';
import React, { useRef, useState } from 'react';

const OtpForm = ({ api, otpSubmitHandler, startResendTimer, handleResendOTP, resendTimer, editNumber }) => {
    const [form] = Form.useForm();
    const [otp, setOtp] = useState([]);    // Assuming 4 digits for OTP
    const otpInputRef = useRef(null);

    const handleOtpChange = (otpValue) => {
        const updatedOtp = [...otp];
        // form.setFieldsValue((data) => {
        //     console.log(data)
        //     return {
        //         ...data
        //     }
        // })
        // updatedOtp[otpValue.index] = otpValue.value; // Update the specific index with the new value
        setOtp(updatedOtp);
        // form.setFieldsValue({ otp: updatedOtp.join('') }); // Join the array to form a single string
    };

    return (
        <div>
            <p className="text-primary font-semibold text-base mb-4">Enter Received OTP</p>
            <div>
                <Form
                    name="otp_form"
                    className="otp-form"
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={(value) => {
                        if (otp?.join("").trim().length !== 4) {
                            return notification.error({ message: "Please fill otp correctly!" });
                        }
                        const otpNum = otp?.join("")?.trim()
                        otpSubmitHandler({
                            otp: +otpNum
                        }); // Pass the form values to your handler
                    }}
                >
                    <Row className="w-full pb-6" gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                name="otp"
                                className="mb-0 w-full"
                            // rules={[{ validator: async () => Promise.resolve() }]}
                            // rules={[{ required: true, message: 'Please input your OTP!' }]}
                            >
                                <InputOTP
                                    inputType="numeric"
                                    className="gap-6 h-12 !w-22 max-w-16"
                                    ref={otpInputRef}
                                    isPreserveFocus
                                    value={otp}
                                    length={4}
                                    size="large"
                                    autoFocus
                                    onChange={(e) => {
                                        setOtp(e);
                                    }}// Handle OTP change
                                />

                            </Form.Item>
                            <p className="mt-4">
                                Didn’t receive the OTP? <span className="text-error cursor-pointer" onClick={() => resendTimer == 0 && handleResendOTP()}>{`${resendTimer > 0 ? `00:${String(resendTimer).padStart(2, '0')}` : "Resend OTP"}`}</span>
                            </p>
                        </Col>
                    </Row>
                    <Form.Item className="w-full">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button w-full py-5"
                            loading={api?.isLoading}
                        >
                            Verify OTP
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div>
                <p>
                    OTP sent on +91 {localStorage.getItem("mobileno") ?? ""}{' '}
                    <span className="text-primary cursor-pointer" onClick={() => editNumber()}>
                        change Number
                    </span>
                </p>
            </div>
        </div>
    );
};

export default OtpForm;
