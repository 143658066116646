import React, { useRef } from "react";
// import "jodit/build/jodit.min.css";
// import JoditEditor from "jodit-react";
// const config = {
//   buttons: ["bold", "italic", "link", "unlink", "underline", "source"],
// };
const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/,
  height: "250px",
  toolbarAdaptive: false, // Ensure the toolbar does not adapt to the screen width

};

const TextEditor = ({ value, onChange, placeholder }) => {
  const editor = useRef(null);
  // const [content, setContent] = useState("");
  return (
    <>
      <div className="relative z-10">
        {/* <JoditEditor
          ref={editor}
          value={value}
          config={config}
          tabIndex={1} // tabIndex of textarea
          // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={onChange}
        /> */}
      </div>

    </>
  );
};

export default TextEditor;
