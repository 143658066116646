import {
  Col,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";
import YoutubeCard from "./component/youtubeCard";
import RequestTask from "./component/requestTask";
import LeftSideProfile from "./component/leftSideProfile";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useParams } from "react-router-dom";
import { getCurrentLocation } from "../../../util/functions";
// user Task
// 1: "InProgress",
//     2: "Pending",
//     3: "Accepted",
//     4: "Rework",
//     5: "Reject",
// Task
// "Active"
// "Inactive"
////////////////////////////////////////////////////////////////////////////////
// userId : 1
// taskId: 88
// userTaskId: 33


function generateRandomNumber() {
  let randomNumber = '';
  for (let i = 0; i < 20; i++) {
    randomNumber += Math.floor(Math.random() * 10);
  }
  return randomNumber;
}

const Dashboard = ({ admindetails, taskData, userData, setRefresh }) => {
  const api = useHttp();
  // const [taskData, setTaskData] = useState({});
  const [status, setStatus] = useState("");
  // const [refresh, setRefresh] = useState(false);
  const { userTaskId, userId } = useParams();

  const taskId = localStorage.getItem("taskId");


  useEffect(() => {

    setStatus(taskData?.userTasks?.length > 0 ? taskData?.userTasks?.[0]?.status ?? "" : "Apply")
    // if (userTaskData?.taskId)
    //   api.sendRequest(apiGenerator(CONSTANTS.API.task.getOne, {
    //     dataId: userTaskData?.taskId //taskid // 
    //   }), (res) => {
    //     setTaskData(res?.data);
    //     setStatus(res?.data?.userTasks?.[0]?.status ?? "")
    //   })

  }, [taskData]);

  const applyUserTaskHandler = async (isModalVisible, isButtonHandler) => {
    const formData = new FormData();
    const imeiNumber = generateRandomNumber();
    const existingIEMINumber = sessionStorage.getItem("IMEI");
    const cityData = await getCurrentLocation();
    const payload = {
      taskId: `${taskId}`,
      affiliateUserId: `${userId}`,    // userid link  mathi
      // IMEI: `${imeiNumber}` ?? "",
    }
    if (cityData) {
      payload["city"] = cityData;
    }
    if (existingIEMINumber) {
      payload["IMEI"] = existingIEMINumber;
    } else {
      sessionStorage.setItem('IMEI', imeiNumber);
      payload["IMEI"] = imeiNumber;
    }

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        formData.append(key, payload[key]);
      }
    }
    api.sendRequest(CONSTANTS.API.userTask.add, async (res) => {
      // setTaskData(res?.data);
      await setRefresh(pr => !pr);
      await isModalVisible();
      await isButtonHandler();
      await setStatus("InProgress");
    }, formData)
  }


  return (
    <>
      <Row className="px- pt-10 pb-10 lg:h-full" gutter={[16, 16]}>
        <Col className="lg:h-full" span={24} md={24} lg={16}>
          <LeftSideProfile taskData={taskData} />
        </Col>
        <Col span={24} sm={24} md={24} lg={8}>
          <Row gutter={[16, 16]} className="">
            <Col span={24}>
              <RequestTask api={api} admindetails={admindetails} taskData={taskData} status={status} applyUserTaskHandler={applyUserTaskHandler} setStatus={setStatus} setRefresh={setRefresh} />
            </Col>
            <Col span={24}>
              <YoutubeCard taskData={taskData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
