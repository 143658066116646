
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, Card, Image, } from "antd";
// import logo from "../../../asset/icons/Headerlogo.svg";
import { getAuthToken, setAuthDetails } from "../../../util/API/authStorage.js";
import CONSTANT, { appRoot } from "../../../util/constant/CONSTANTS.js";
import dummyAvtar from "../../../asset/image/dummy-avatar.jpg";
import useHttp from "../../../hooks/use-http.js";
import { apiGenerator } from "../../../util/functions.js";
import LoginWithNumber from "./loginWithNumber.js";
import OtpForm from "./otpForm.js";
import CONSTANTS from "../../../util/constant/CONSTANTS.js";


function storeIds(userTaskId, userId,) {
    const storedUserTaskId = localStorage.getItem("userTaskId");
    const storedUserId = localStorage.getItem("userId");

    if (!storedUserTaskId && !storedUserId) {
        localStorage.setItem("userTaskId", userTaskId);
        localStorage.setItem("userId", userId);
    }
}

const TestLogin = ({ userData,unAuth }) => {
    const userRole = localStorage.getItem("userRole");
    const navigate = useNavigate();
    const api = useHttp();
    const userId = localStorage.getItem("userId");
    const userTaskId = localStorage.getItem("userTaskId");
    const location = useLocation();
    const [form] = Form.useForm();
    const deafaultTimer = 20;

    // const [userData, setUserData] = useState({});
    const [step, setStep] = useState(0);
    const [resendTimer, setResendTimer] = useState(deafaultTimer);
    const [timer, setTimer] = useState(null);
    const [invalidAttempts, setInvalidAttempts] = useState(0);
    const [countdownTimer, setCountdownTimer] = useState(0);
console.log(userData)
    useEffect(() => {

        const pathMatch = location?.pathname?.split("/");
        if (location?.pathname?.includes("task") && pathMatch?.length > 2) {
            const userTaskId = location?.pathname?.split("/")?.[2]
            const userId = location?.pathname?.split("/")?.[3]
            return storeIds(userTaskId, userId);
        } else if (!userId || !userTaskId) return navigate("/error");

        const isLogin =
            getAuthToken() !== undefined &&
            getAuthToken() !== null;
        // if (!isLogin) return notification.error({ message: "Session Expired!" });
        if (isLogin) return navigate(`${appRoot}`);


    }, [])

    useEffect(() =>  {
        if(unAuth) return navigate("/error")

    },[unAuth])

    useEffect(() => {
        // if (!userTaskId || !userId) return;
        // console.log(userId, userTaskId, "jghjgj")
        // api.sendRequest(
        //     apiGenerator(CONSTANT.API.getClient, {}, `?userTaskId=${userTaskId}&userId=${userId}`), (res) => {
        //         localStorage.setItem("taskId", res?.data?.taskId);;
        //         setUserData(res?.data?.user)
        //     }
        // )
    }, [userTaskId, userId]);

    useEffect(() => {
        if (invalidAttempts > 3) {
            // If the user has made 3 invalid attempts, start a 5-minute countdown
            setCountdownTimer(300);
            const countdownInterval = setInterval(() => {
                setCountdownTimer((prevTimer) => {
                    if (prevTimer > 0) {
                        return prevTimer - 1;
                    } else {
                        clearInterval(countdownInterval);
                        setInvalidAttempts(0); // Reset invalid attempts after the 5-minute window
                        return 0;
                    }
                });
            }, 1000);
            return () => {
                clearInterval(countdownInterval);
            };
        }
    }, [invalidAttempts]);
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const startResendTimer = () => {
        // Set a countdown timer for 120 seconds to allow resend OTP
        if (timer) {
            clearInterval(timer);
        }
        let newTimer = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer == 1) {
                    clearInterval(newTimer);
                }
                return prevTimer - 1;
            });
        }, 1000);
        setTimer(newTimer);
    };

    const sendOTP = async (values) => {
        try {
            // startResendTimer();
            setResendTimer(deafaultTimer);
            MobileSubmitHandler({
                mobile: localStorage.getItem("mobileno"),
            }, true);

        } catch (error) {
            console.error("Failed to send OTP:", error);
        }
    };
    const handleResendOTP = () => {
        if (countdownTimer > 0) {
            // console.log("Please wait for the cooldown period to finish.");
        } else if (resendTimer === 0) {
            // Call sendOTP function again to resend OTP
            sendOTP();
        }
    };

    const handleEditNumber = () => {
        if (timer) {
            clearInterval(timer);
        }
        form.resetFields(["otp"]);
        setResendTimer(deafaultTimer);
        setCountdownTimer(0);
        setStep(0); // Set step back to 1
    };


    const MobileSubmitHandler = (value, isRensend = false) => {
        // value?.countryCode && 
        if (value?.mobile) {
            api.sendRequest(CONSTANTS.API.sendOTP, (data) => {
                setStep(1);
                startResendTimer();
                localStorage.setItem("mobileno", value?.mobile);
                localStorage.setItem("otpToken", data?.token);
            }, { mobile: `${value?.mobile}`, countryCode: "+91" }, `OTP ${isRensend ? "Resent" : "Sent"} Successfully`
            )

            // notification.success({
            //     message: "OTP Sent Successfully",
            // })
        }
    }

    const otpSubmitHandler = (value) => {
        value["token"] = localStorage.getItem("otpToken")
        api.sendRequest(CONSTANTS.API.verifyOTP, (data) => {
            localStorage.setItem("userType", data?.userType);
            if (data?.userType === "old") {
                setAuthDetails(data?.token);
                window.location.assign(appRoot)
            } else {
                localStorage.setItem("signupToken", data?.token)
                window.location.assign("/registration")
            }
        }, value, "LogIn Successfully!!!"
        )
    };

    return (
        <React.Fragment>
            <div
                className="h-screen flex justify-center  items-center bg-[#F0F2F5] overflow-hidden px-10">
                <div className="p-5 flex flex-col items-center justify-center">
                    <Card className="px-5 py-4 rounded-3xl w-full xs:w-[75%] xs:min-w-[350px] ">
                        <div className="flex mb-10">
                            <Image
                                src={userData?.image ?? dummyAvtar}
                                alt="Teektask Logo"
                                className="object-cover"
                                preview={false}
                                style={{ borderRadius: "50%", aspectRatio: 1 / 1 }}
                                width={100}
                            />
                            <div className="flex justify-center items-center ps-5">
                                <div>
                                    <p className="text-primary text-[14px] xs:text-[13px]">{userData?.name && "Team Leader"}</p>
                                    <p className="text-[24px] xs:text-[17px]">{userData?.name ?? ""}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            {
                                step == 0 ? <LoginWithNumber api={api} MobileSubmitHandler={MobileSubmitHandler} /> :
                                    <OtpForm
                                        api={api}
                                        otpSubmitHandler={otpSubmitHandler}
                                        startResendTimer={startResendTimer}
                                        handleResendOTP={handleResendOTP}
                                        resendTimer={resendTimer}
                                        editNumber={handleEditNumber} />
                            }
                            {/* <LoginWithNumber MobileSubmitHandler={MobileSubmitHandler} /> */}
                        </div>
                        <div className="mt-6">
                            <Image
                                src="Banner.png"
                                preview={false}
                                style={{ aspectRatio: 430 / 210, maxWidth: 430 }}
                            />
                        </div>
                    </Card>
                    <p className="text-primary py-3 cursor-pointer"
                        onClick={() => window.open(`https://api.whatsapp.com/send?phone=${userData?.mobile}`, '_blank')}>Help & Support</p>
                </div>

            </div>
        </React.Fragment>
    );
}

export default TestLogin