import { useState, useCallback } from "react";
import { notification } from "antd";
import Services from "../util/API/service";
import { deleteAuthDetails } from "../util/API/authStorage";
import { loginRoot } from "../util/constant/CONSTANTS";
import axios from "axios";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = useCallback(
    async (url, responseHandler, payload, successMessage, errorHandler) => {
      setIsLoading(true);
      if (url?.endpoint && url?.type) {
        try {
          let response;
          switch (url.type) {
            case "POST":
              response = await Services.post(url.endpoint, payload);
              break;

            case "PUT":
              response = await Services.put(url.endpoint, payload);

              break;
            case "DELETE":
              response = await Services.delete(url.endpoint);
              break;

            case "PATCH":
              response = await Services.patch(url.endpoint, payload);
              break;

            default:
              const queryParams = buildQueryString(payload);
              response = await Services.get(url.endpoint + queryParams);
              break;
          }

          const data = await response.data;
          if (successMessage) {
            notification.success({ message: successMessage, duration: "3" });
          }
          try {
            if (responseHandler) {
              responseHandler(data);
            }
          } catch (e) {
            console.log(e);
          }
        } catch (err) {
          console.log(err);
          if (err?.response?.status === 500) {
            deleteAuthDetails()
            window.location.assign('/')
          }
          if (err?.response?.data?.message === 'You are not authorized person') {
            deleteAuthDetails();
            // window.location.href("/error");
            // navigate("/error")
            notification.error({
              message: err?.response?.data?.message,
              duration: "3",
            });

            if (errorHandler) {
              errorHandler(err?.response?.data?.message);
            }
          }
          else if (err?.response?.data?.message) {
            notification.error({
              message: err?.response?.data?.message,
              duration: "3",
            });
          }
          else {
            notification.error({ message: "Something Wrong Please Try again" });
          }
        }
      } else {
        notification.error({ message: "Not valid endpoint and type" });
      }
      setIsLoading(false);
    },
    []
  );

  const sendBulkRequest = useCallback(
    async (urls, responseHandler, successMessage, errorHandler) => {
      setIsLoading(true);
      try {
        let response;
        response = await axios.all(
          urls?.map((url) => {
            switch (url?.url?.type) {
              case "POST":
                return Services.post(url?.url?.endpoint, url?.payload);

              case "PUT":
                return Services.put(url?.url?.endpoint, url?.payload);

              case "DELETE":
                return Services.delete(url?.url?.endpoint);

              case "PATCH":
                return Services.patch(url?.url?.endpoint, url?.payload);

              case "GET_AXIOS":
                return axios.get(url?.url?.endpoint);

              default:
                return Services.get(url?.endpoint);
            }
          })
        );

        if (successMessage) {
          notification.success({ message: successMessage, duration: "3" });
        }
        try {
          if (responseHandler) {
            responseHandler(response);
          }
        } catch (e) {
          console.log(e);
        }
      } catch (err) {
        console.log(err?.response?.data?.message);
        if (err?.response?.data?.message === "jwt expired" || err?.response?.data?.message === "invalid signature") {
          deleteAuthDetails();
          localStorage.removeItem("name");
          localStorage.removeItem("email");
          localStorage.removeItem("token");
          window.location.reload();
        }

        if (err?.response?.data?.message) {
          notification.error({
            message: err?.response?.data?.message,
            duration: "3",
          });
          if (errorHandler) {
            errorHandler(err?.response?.data?.message);
          }

          return;
        }

        // return notification.error({
        //   message: "Something Wrong Please Try again",
        // });
      }
      setIsLoading(false);
    },
    []
  );

  return {
    isLoading,
    sendRequest,
    sendBulkRequest
  };
};

export default useHttp;

export const buildQueryString = (params) => {
  const queryParts = [];

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];

      if (key.startsWith("autogenerate-mul-array-") && Array.isArray(value)) {
        // Handle autogenerate-mul-array- with array values
        console.log(key, "key");
        const arrayKey = key.slice("autogenerate-mul-array-".length);
        value.forEach((item) => {
          queryParts.push(
            `${encodeURIComponent(arrayKey)}=${encodeURIComponent(item)}`
          );
        });
      } else {
        // Handle other cases
        queryParts.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
};
