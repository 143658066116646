import { Button, Form, notification, Result, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { InboxOutlined } from "@ant-design/icons";
import { apiGenerator, getCurrentLocation } from '../../../../util/functions';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import useHttp from '../../../../hooks/use-http';


const TaskSubmissionModal = ({ formattedPathapply, isSucess, setIsSucess, form, isButton, setIsButton, taskData, admindetails, setStatus, isModalVisible, setRefresh }) => {
    // const [form] = Form.useForm();
    // const isSucess = false;
    // const [isSucess, setIsSucess] = useState(false);

    // // const { userTaskId, userId } = useParams();
    const api = useHttp();
    // const formattedPathapply = taskData?.availableFileType ? '.' + taskData?.availableFileType?.split(',')?.join(',.') : "all";
    // useEffect(() => {
    //     if (!isSucess) return
    //     const timer = setTimeout(() => {
    //         setRefresh(pr => !pr)
    //         isModalVisible();
    //         form?.resetFields();
    //         setIsSucess(pr => !pr)
    //     }, 3000);
    //     return () => clearTimeout(timer);
    // }, [isSucess]);

    const submissiontakHandler = () => {
        form?.validateFields()?.then(async (payload) => {

            const cityData = await getCurrentLocation();
            const formPayload = new FormData();
            const arr = payload?.proofOfWork?.map((el, i) => {
                formPayload.append(`proofOfWork`, el?.originFileObj)
                return el?.originFileObj
            })
            cityData && formPayload.append("city", cityData);
            api.sendRequest(apiGenerator(CONSTANTS.API.userTask.update, {
                dataId: taskData?.userTasks?.[0]?.id
            }), (res) => {
                // setTaskData(res?.data);
                // setStatus("InProgress");
                // setRefresh(pr => !pr)
                setIsSucess(pr => !pr);
            }, formPayload)
        }).catch((info) => {
            console.log("Validate Failed:", info);
            notification.error({
                message: "Please Select File",
            })
        });
    }

    return (
        <>
            {!isSucess ? <div>
                <p className='py-2 font-semibold'>
                    Proof of Work
                </p>
                <span className='text-[#7D8995] pb-3'>Attachments will be a part of this project.</span>
                <div className='py-2 min-h-72'>
                    <Form form={form}>
                        <Form.Item
                            name={"proofOfWork"}
                            className="form"
                            valuePropName="fileList"
                            required={true}
                            getValueFromEvent={(e) => {
                                console.log("Upload event:", e);
                                if (Array.isArray(e)) {
                                    return e;
                                }
                                setIsButton(pr => e?.fileList?.length > 0 ? true : false)
                                return e?.fileList;
                            }}

                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: "Please Select File",
                                },
                            ]}
                        >
                            <Upload.Dragger
                                name={"proofOfWork"}
                                id={"proofOfWork"}
                                accept={formattedPathapply ?? "image/*"}
                                multiple
                                beforeUpload={() => {
                                    return false;
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Click or drag file to this area to upload
                                </p>
                                <p className="ant-upload-hint">
                                    Support for a single or bulk upload.
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form>
                </div>

                <Button className={`mt-2 w-full`} type='primary' loading={api?.isLoading} color='#DBECFF80' style={{
                    background: !isButton ? "#DBECFF80" : "#007BFF",
                    color: !isButton ? "#007BFF" : "#ffffff",
                    // width: "100%",
                }} onClick={() => submissiontakHandler()}>
                    Apply
                </Button>
            </div> : <div>
                <Result
                    status="success"
                    style={{

                    }}
                    title={<p className='font-semibold pt-10 pb-4 text-3xl'>Work Submitted</p>}
                    subTitle={`Thank you, ${admindetails?.name ?? ""}! Your ${taskData?.name ?? ""} work has been successfully submitted. Our team will review it and update you on the status shortly.`}
                    extra={[

                    ]}
                />
            </div>}
        </>
    )
}

export default TaskSubmissionModal