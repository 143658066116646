import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import React from 'react'

const LoginWithNumber = ({ api, MobileSubmitHandler }) => {


    const phoneValidator = (_, value) => {
        if (!value || /^\d{10}$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject("Please enter 10-digit phone number!");
    };

    return (
        <>
            <div>
                <p className="text-primary font-semibold text-base mb-4">Login With Mobile</p>
                <div>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={MobileSubmitHandler}
                    >
                        <Row className="w-full pb-8" gutter={[16, 16]}>
                            <Col span={8} sm={8} md={5} className="">
                                <p className='' style={{
                                    height: '38px',
                                    padding: '7px 11px',
                                    background: '#ffffff',
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '8px',
                                    textAlign: 'center'
                                }}>+91</p>
                                {/* <Form.Item name="countryCode" noStyle initialValue="+91">
                                    <Select className="" value={"+91"} size="large">
                                        <Select.Option value="+91">+91</Select.Option>
                                        <Select.Option value="+1">+1</Select.Option>
                                    </Select>
                                </Form.Item> */}
                            </Col>
                            <Col span={16} sm={16} md={19} className="!p-0">
                                <Form.Item
                                    name="mobile"
                                    // label="Enter Your Phone Number"
                                    className="mb-4 w-full"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your phone number!",
                                        },
                                        {
                                            validator: phoneValidator,
                                        },
                                    ]}
                                // validateStatus={backendError ? "error" : ""}
                                // help={backendError}
                                >
                                    <InputNumber
                                        autoFocus
                                        // prefix={prefixSelector}
                                        //   addonBefore={prefixSelector}
                                        styles={{ addonBefore: { padding: "0px" } }}
                                        controls={false}
                                        // classNames=""
                                        size="large"
                                        placeholder="Enter Number"
                                        className=" input-addon w-full "
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item className="w-full">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button w-full py-5"
                                loading={api?.isLoading}

                            // disabled
                            >
                                Send OTP
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div>
                    <p>By proceeding you agree to the{" "}
                        <a target='_blank' href="https://teektask.com/Terms.html">Terms of use</a>
                        {" "}and{" "}
                        <a target='_blank' href="https://teektask.com/Policy.html">Privacy Policy.</a></p>
                </div>
            </div>
        </>
    )
}

export default LoginWithNumber