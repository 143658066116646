import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Flex, Image, message, Upload } from 'antd';

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

// const beforeUpload = (file) => {
//     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//     if (!isJpgOrPng) {
//         message.error('You can only upload JPG/PNG file!');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//         message.error('Image must smaller than 2MB!');
//     }
//     return isJpgOrPng && isLt2M;
// };
const ImageProfileUploader = ({
    name = "logo",
    disabled = false,
    // previewFile={}
    formData = "",
    form = null,
    listType = "picture",
    accept = "image/*",
    maxCount = 1,
}) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(formData || null);
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.fileList) {
            // Get the URL from the server response or use local preview
            const oldData = form.getFieldsValue();
            form.setFieldsValue({ ...oldData, [name]: info.fileList });
            getBase64(info.fileList?.[0]?.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const handlePreviewClick = (e) => {
        e.stopPropagation(); // Prevent the upload dialog from opening
    };
    const handleDelete = (e) => {
        e.stopPropagation(); // Prevent any other click events
        const oldData = form.getFieldsValue();
        form.setFieldsValue({ ...oldData, [name]: null });
        setImageUrl(null); // Reset the image
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    return (
        <Flex className='flex items-center' gap="middle" wrap>

            <Upload
                name={name}
                // showUploadList={{
                //     showPreviewIcon: true,
                //     showRemoveIcon: true,
                // }}
                listType={listType}
                className="avatar-uploader"
                showUploadList={false}

                maxCount={maxCount}
                accept={accept}
                onRemove={() => setImageUrl(null)}
                beforeUpload={() => {
                    return false;
                }}
                style={{
                    width: '100px',
                    height: '100px',
                }}

                onChange={handleChange}
            >
                {imageUrl ? (
                    <div onClick={handlePreviewClick}>
                        <Image
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                            }}
                        />
                    </div>
                ) : (
                    uploadButton
                )}
            </Upload>
            {imageUrl &&
                <DeleteOutlined
                    onClick={handleDelete}
                    style={{
                        // position: 'absolute',
                        // top: "100px",
                        // left: "100px",
                        fontSize: 15,
                        color: 'red',
                        cursor: 'pointer',
                    }}
                />
            }
        </Flex>
    );
};

export default ImageProfileUploader;
