import { Button, Image, Result } from 'antd'
import React from 'react'
import withdraIcon from "../../../../asset/image/withdrawal-request.png"
import useHttp from '../../../../hooks/use-http';
import { apiGenerator } from '../../../../util/functions';
import CONSTANTS from '../../../../util/constant/CONSTANTS';

const WithdrawModal = ({ isModalVisible, setRefresh, taskData }) => {
    const api = useHttp();

    const withdrawTaskHandler = () => {

        api.sendRequest(apiGenerator(CONSTANTS.API.userTask.updateWithdraw, {
            dataId: taskData?.userTasks?.[0]?.id
        }), (res) => {
            // setTaskData(res?.data);
            // setStatus("InProgress");
            isModalVisible();
            setRefresh(pr => !pr)

        }, {})

    }

    return (
        <>
            <div className='px-5'>
                <Result
                    className='p-5 pb-0'
                    status="success"
                    title={<p className='font-semibold pt-10 pb-4 text-2xl'>Withdrawal the request</p>}
                    icon={<Image src={withdraIcon} alt='withdraIcon' />}
                    subTitle={<p className='pb-10'>Previously sent files will be deleted, files will have to be sent again.</p>}
                    extra={[
                        <div className='flex justify-between px-5'>
                            <Button style={{
                                borderColor: "#DC3545",
                                background: "#F9DDE0",
                                color: "#DC3545",
                                width: "40%",
                            }} onClick={() => isModalVisible()} key="console">
                                No
                            </Button>
                            <Button loading={api?.isLoading} onClick={() => withdrawTaskHandler()} className='w-[40%]' type='primary' danger key="buy">Yes</Button></div>
                    ]}
                />
            </div>
        </>
    )
}

export default WithdrawModal